<template>
  <div>
    <breadCrumb :title="$t('gen-zong-ding-dan')" :menuList="menuList"></breadCrumb>

    <section class="mb-5">
      <div class="container text-left">
        <div class="row">
            <div class="col-xxl-5 col-xl-6 col-lg-8 mx-auto">
                <form class="" >
                    <div class="bg-white rounded shadow-sm">
                        <div class="fs-15 fw-600 p-3 border-bottom text-center">
                           {{ $t('jian-cha-nin-de-ding-dan-zhuang-tai') }} </div>
                        <div class="form-box-content p-3">
                            <div class="form-group">
                                <input type="text" class="form-control mb-3" :placeholder="$t('ding-dan-bian-hao')" name="order_code" required>
                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-primary">{{ $t('gen-zong-ding-dan-0') }}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="bg-white rounded shadow-sm mt-5">
          <div class="fs-15 fw-600 p-3 border-bottom">
            {{ $t('ding-dan-hui-zong') }} </div>
          <div class="p-3">
            <div class="row">
              <div class="col-lg-6">
                <table class="table table-borderless">
                  <tr>
                    <td class="w-50 fw-600">{{ $t('ding-dan-bian-hao-0') }}</td>
                    <td>{{ orderInfo.code }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('ke-hu-0') }}</td>
                    <td>{{ orderInfo.username }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('you-jian') }}</td>
                    <td>{{ orderInfo.email }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('shou-huo-di-zhi-0') }}</td>
                    <td>{{ orderInfo.address }}, {{ orderInfo.city }}, {{ orderInfo.country }}</td>
                  </tr>
                </table>
              </div>
              <div class="col-lg-6">
                <table class="table table-borderless">
                  <tr>
                    <td class="w-50 fw-600">{{ $t('xia-dan-ri-qi') }}</td>
                    <td>{{ orderInfo.date }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('ding-dan-zonge') }}</td>
                    <td>{{ orderInfo.totalAmount }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('fa-huo-fang-shi') }}</td>
                    <td>{{ orderInfo.shippingType }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('zhi-fu-fang-shi') }}</td>
                    <td>{{ orderInfo.payType }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('jiao-huo-zhuang-tai-0') }}</td>
                    <td>{{ orderInfo.statusName }}</td>
                  </tr>
                  <tr>
                    <td class="w-50 fw-600">{{ $t('gen-zong-dai-ma') }}</td>
                    <td>{{ orderInfo.trackOrder}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>


        <div class="bg-white rounded shadow-sm mt-4" v-for="(item, i) in orderList" :key="i">
          <div class="p-3">
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t('chan-pin-ming-cheng') }}</th>
                  <th>{{ $t('shu-liang-0') }}</th>
                  <th>{{ $t('fa-huo-ren') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ item.productName }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ item.username }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('gen-zong-ding-dan-1'),
          path: ''
        },
      ],
      form: {
        code: '',
      },
      orderList: [],
      orderInfo: {}
    }
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    addFile(type) {
      this.fileType = type
      document.getElementById('fileInput').click()
    },
    uploadFile() {

    }
  }
}
</script>